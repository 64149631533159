/**
 * This class fixes the Cumulative Layout Shift (CLS) caused by the LiveChat widget
 *
 * LiveChat is a 3rd party widget that is loaded asynchronously and causes a Layout Shift when it is loaded due to
 * changing its size multiple times before stabilizing on the size it's going to be displayed in. This happens in less
 * than a couple of seconds, and the widget is transparent, so it's imperceptible to the user, but causes Google's Core
 * Web Vitals metrics to be affected.
 */
class LiveChatClsFixer {
  // Constants
  TIME_INCREMENT_MS = 100;
  WAIT_FOR_LIVECHAT_TIMEOUT_MS = 10000;
  LIVECHAT_DOM_ELEMENT_ID = 'chat-widget-container';

  // Fields
  dimensions = { width: null, height: null };
  timeSinceLastChange = 0;

  constructor() {
    this.waitForLiveChat(() => {
      this.preventClsOnPageLoad();
      this.preventClsWhenMinimizing();
    });
  }

  /**
   * Wait for the LiveChatWidget object to be available and execute a callback when it is.
   *
   * @param {function} callback A function to execute when the LiveChatWidget object is available
   * @param {Number} tries The number of attempts to wait for the LiveChatWidget object to be available
   */
  waitForLiveChat(callback, tries = 0) {
    if (window.LiveChatWidget) {
      callback();
    } else if (tries < this.WAIT_FOR_LIVECHAT_TIMEOUT_MS / this.TIME_INCREMENT_MS) {
      setTimeout(() => this.waitForLiveChat(callback, tries + 1), this.TIME_INCREMENT_MS);
    }
  }

  /**
   * Prevent a Layout Shift on page load by hiding the widget until its dimensions have stabilized.
   */
  preventClsOnPageLoad() {
    window.LiveChatWidget.on('ready', () => {
      const { visibility } = window.LiveChatWidget.get('state');
      if (visibility == 'maximized') {
        // No Layout Shift occurs if widget is maximized from a previous interaction.
        return;
      }
      this.hideWidget();
      this.showWidgetWhenStable();
    });
  }

  /**
   * Prevent a Layout Shift when the widget is minimized by hiding the widget until its dimensions have stabilized.
   */
  preventClsWhenMinimizing() {
    window.LiveChatWidget.on('visibility_changed', ({ visibility }) => {
      if (visibility == 'minimized' || visibility == 'hidden') {
        this.hideWidget();
        this.showWidgetWhenStable();
      }
    });
  }

  /**
   * Hide the widget and reset the variables for keeping track of changes.
   */
  hideWidget() {
    document.getElementById(this.LIVECHAT_DOM_ELEMENT_ID).style.opacity = 0;
    this.dimensions = { width: null, height: null };
    this.timeSinceLastChange = 0;
  }

  /**
   * Determine if the widget has stabilized and show it if it has.
   */
  showWidgetWhenStable() {
    let changes = this.detectChanges();
    this.dimensions = changes || this.dimensions;
    this.timeSinceLastChange = changes ? 0 : this.timeSinceLastChange + this.TIME_INCREMENT_MS;

    if (this.hasStabilized(parseInt(this.dimensions.width), parseInt(this.dimensions.height))) {
      document.getElementById(this.LIVECHAT_DOM_ELEMENT_ID).style.opacity = 1;
    } else {
      setTimeout(() => this.showWidgetWhenStable(), this.TIME_INCREMENT_MS);
    }
  }

  /**
   * Detect if the width and height of the widget have changed since the last iteration.
   *
   * @returns {Object|null} An object containing the width and height of the widget if they have changed, otherwise null
   */
  detectChanges() {
    const element = document.getElementById(this.LIVECHAT_DOM_ELEMENT_ID);
    let newDimensions = element ? element.style : {};
    for (let field in this.dimensions) {
      if (this.dimensions[field] !== newDimensions[field]) {
        return Object.keys(this.dimensions).reduce(
          (result, field) => ({ ...result, [field]: newDimensions[field] }),
          {}
        );
      }
    }
  }

  /**
   * Check if the width and height of the widget have stabilized and we can display it.
   *
   * The widget is considered stable and should not cause a Layout Shift based on our measurements if:
   * - it dimensions have been the minimized size stably for more than 1.5 seconds
   * - after 3 seconds, if it has stabilized to any size (this is also a fail-safe in case the minimized size changes)
   *
   * @param {Number} width  The width of the widget
   * @param {Number} height The height of the widget
   */
  hasStabilized(width, height) {
    const TIME_STABLE_MS = 1500;
    const MAX_TIMEOUT_MS = 3000;

    return (
      (this.isMinimizedSize(width, height) && this.timeSinceLastChange > TIME_STABLE_MS) ||
      this.timeSinceLastChange > MAX_TIMEOUT_MS
    );
  }

  /**
   * Check if the width and height are close to the minimized size with a margin of error, as this size varies
   *
   * The minimized height varies from around 155 to 215 pixels, the minimized width varies from around 279 to 327 pixels
   * and there is a margin of error in case these sizes change slightly as we don't have full control of the 3rd party
   * widget
   *
   * @param {Number} width  The width of the widget
   * @param {Number} height The height of the widget
   */
  isMinimizedSize(width, height) {
    const MINIMIZED_HEIGHT = 200;
    const MINIMIZED_WIDTH = 300;
    const MARGIN_OF_ERROR = 50;

    return Math.abs(width - MINIMIZED_WIDTH) < MARGIN_OF_ERROR && Math.abs(height - MINIMIZED_HEIGHT) < MARGIN_OF_ERROR;
  }
}

window.addEventListener('load', () => new LiveChatClsFixer());
